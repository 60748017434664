<template>
  <div>
    <div v-if="!pageLoaded" style="padding-top:130px; height: 280px;">
      <div class="loader" style="text-align: center;"></div>
    </div>
    <div v-else style="padding-top: 56px">
      <div :class="'mobileNavBar ' + deviceType">
        <div class="appBar-header">
          <div @click="routeBack('/',false)" class="header-route-back">
        <span v-if="deviceType === 'ios'">
          <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
            <span v-else>
          <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
          </div>
          <div class="header-title-container">
            <p class="header-title">
              Destek Merkezi
            </p>
          </div>
          <div class="tickets" @click="">
            <router-link to="/tickets" style="text-decoration: none">
              <img src="https://gcdn.bionluk.com/site/icon/ic_add.svg" alt="">
            </router-link>
          </div>
        </div>
      </div>

      <div v-if="pageLoaded">
        <div class="support-categories">
          <ul class="support-category-list">

            <li class="support-category-list-item-header">{{ category.title }}</li>
            <li v-for="article in category.articles" class="support-category-list-item">
              <div class="support-category-list-item-detail" @click="$router.push(`${prefixPath}destek/${article.slug}`)">
                <div style="text-decoration: none" class="support-category-list-item-detail-text">
                  <p class="support-category-list-item-detail-name">{{ article.title }}</p>
                </div>


                <div>
                  <img src="https://gcdn.bionluk.com/site/icon/tansel_mobil_ws_ok.svg" alt="">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div class="loader" style="text-align: center"></div>
      </div>
    </div>
  </div>
</template>

<script>


 export default {
   name: "src-pages-body-staticContents-support-categoryList-v1",
   data() {
     return {

       slug:null,
       pageLoaded:false,
       category:{
         id:null,
         parent_id:null,
         sub_categories:[],
         articles:[]
       },
       other_categories:[],
       main_categories:[],


     };
   },
   methods: {

     getTopics(){

       this.pageLoaded = false;
       this.api.content
         .retrieveSupportTopicsv2(this.slug)
         .then(({ data }) => {
           let result = data;
           if (result.success) {

             this.$store.state.indexHTMLTitle = result.data.meta.title;
             //this.$store.state.indexHTMLMetaDescription = result.data.meta.description;

             this.category = result.data.category;
             this.other_categories = result.data.other_categories;
             this.main_categories = result.data.main_categories;

           } else {
             this.$router.push(result.data.redirect_url);
           }
           this.pageLoaded = true;
         })
         .catch(err => {
           this.pageLoaded = true;
           this.$toasted.global.errorToast({
             description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
           });
         });
     }
   },
   computed:{
     navBarData: function () {

       if(this.category.parent_id){

         return {
           navBarList: [
             {
               title: "Destek",
               path: "/destek"
             },
             {
               title: this.category.parent_title,
               path: "/destek/kategori/"+this.category.parent_slug
             },
             {
               title: this.category.title,
               path: "/destek/kategori/"+this.category.slug
             }
           ],
           buttonTitle: "Yardım Merkezine Dön",
           buttonPath: "/destek"
         }
       } else {
         return {
           navBarList: [
             {
               title: "Destek",
               path: "/destek"
             },
             {
               title: this.category.title,
               path: "/destek/kategori/"+this.category.slug
             }
           ],
           buttonTitle: "Destek Taleplerim",
           buttonPath: "/tickets"
         }
       }

     }
   },
   watch:{
     '$store.state.route.path': function (newValue, oldValue) {
       if(newValue && newValue !== oldValue){
         let pathArr = this.$store.state.route.fullPath.split('/');
         if(pathArr && pathArr[3]){
           this.slug =  pathArr[3];
           this.getTopics();
         }
       }
     }
   },
   created() {
     document.body.scrollTop = 0;
     let pathArr = this.$store.state.route.fullPath.split('/');
     if(pathArr && pathArr[3]){
       this.slug =  pathArr[3];
       this.getTopics();
     } else {
       this.$router.push('/destek')
     }

   }
 };
</script>

<style scoped lang="scss">
  .support-category-list{
    display: flex;
    flex-direction: column;
  }

  .support-category-list-item{
    padding: 19px 0;
    border-bottom: 1px solid #dfe4ec;
    padding-right: 5px;
  }

  .support-category-list-item-detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .support-category-list-item-detail-text{
    display: flex;
    color: #8b95a1;
  }

  .support-categories{
    padding: 10px 15px 50px 15px;
  }

  .support-category-list-item-detail-name{
    padding-left: 14px;
  }

  .support-category-list-item-header{
    font-weight: 600 !important;
    color: #242424;
    padding: 5px 0 5px 15px;
  }

  .bread {
    font-size: 14px;
    color: #2d3640;
  }

  .bread span {
    color: #b2bcc8;
    text-decoration: none !important;;
  }
</style>
